/*----------------------------------------------------------------------------*\
    GRID
    .grid, .grid__cell

\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $use-grid: true !default;

    // MODIFIERS
    $enable-grid-rev:    false !default;
    $enable-grid-right:  false !default;
    $enable-grid-center: false !default;
    $enable-grid-vertical: false !default;
    $enable-grid-narrow: false !default;
    $enable-grid-wide:   false !default;
    $enable-grid-flush:  false !default;

/*  Component
\*----------------------------------------------------------------------------*/

    @if $use-grid == true {
    
        .grid {
            text-rendering: optimizespeed;
            font-size: 0;
            @include rem(margin-left, -($space));
            padding: 0;
            list-style: none;
            text-align: left;
        }

        .grid__cell {
            @include rem(padding-left, $space);
            display: inline-block;
            width: 100%;
            margin: 0;
            vertical-align: top;
            text-align: left;
            @include font-size($base-font-size, false);
        }

        .grid__cell--center {
            display: block;
            margin: 0 auto;
        }

        // MODIFIER: REVERSE DIRECTION
        @if $enable-grid-rev == true {
            .grid--rev {
                direction: rtl;

                > .grid__cell {
                    direction: ltr;
                }
            }
        }

        // MODIFIER: ALIGN RIGHT
        @if $enable-grid-right == true {
            .grid--right {
                text-align: right;
            }
        }

        // MODIFIER: ALIGN CENTER
        @if $enable-grid-center == true {
            .grid--center {
                text-align: center;

                > .grid__cell {
                    text-align: left;
                }
            }
        }

        // MODIFIER: ALIGN VERTICALLY
        @if $enable-grid-vertical == true {
            .grid--middle {
                > .grid__cell {
                    vertical-align: middle;
                }
            }
            .grid--bottom {
                > .grid__cell {
                    vertical-align: bottom;
                }
            }
        }

        // MODIFIER: NARROWER GUTTERS
        @if $enable-grid-narrow == true {
            .grid--narrow {
                @include rem(margin-left, -($space / 2));

                > .grid__cell {
                    @include rem(padding-left, ($space / 2));
                }
            }
        }

        // MODIFIER: WIDER GUTTERS
        @if $enable-grid-wide == true {
            .grid--wide {
                @include rem(margin-left, -($space * 2));

                > .grid__cell {
                    @include rem(padding-left, ($space * 2));
                }
            }
        }

        // MODIFIER: NO GUTTERS
        @if $enable-grid-flush == true {
            .grid--flush {
                margin-left: 0;

                > .grid__cell {
                    padding: 0;
                }
            }
        }
    }