/*----------------------------------------------------------------------------*\
    BUTTON
    link indicators
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    // default button
    .button {
        border: 0;
        font-weight: 700;
        text-transform: uppercase;
        border-radius: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;

        @include font-size($base-font-size);
        @include rem(padding, $space*.75);

        &:active{
            color: color(blank);
            transition: none;
            transform: translateY(1px);
        }
    }

    // base color outline button
    .button--base {
        box-shadow: 0 0 0 1px rgba(color(base), .5) inset;
        color: color(base);

        &:active{
            background-color: color(base);
            box-shadow: 0 0 0 1px color(base-dark) inset;
        }
    }

    // brand color outline button
    .button--brand {
        box-shadow: 0 0 0 1px rgba(color(brand), .5) inset;
        color: color(brand);

        &:active{
            background-color: color(brand);
            box-shadow: 0 0 0 1px color(brand-dark) inset;
        }
    }

    // blank color outline button
    .button--blank {
        box-shadow: 0 0 0 1px rgba(color(blank), .5) inset;
        color: color(blank);

        &:active{
            background-color: color(blank);
            box-shadow: 0 0 0 1px color(blank) inset;
            color: color(base);
        }
    }

    // less heavy text variant button
    .button--light {
        font-weight: 400;
        text-transform: none;
    }

    // button with arrow on the right
    .button--has-arrow {

        // the arrow image
        .button__arrow {
            display: inline-block;
            vertical-align: top;
            fill: color(base);
            @include rem(margin-left, $space);
        }

        &:active{

            // the arrow image
            .button__arrow {
                fill: color(blank);
                transition: none;
            }
        }

        // blank button with arrow on the right
        &.button--blank {

            // the arrow image
            .button__arrow {
                fill: color(blank);
            }

            &:active{

                // the arrow image
                .button__arrow {
                    fill: color(base);
                }
            }
        }

        .button__arrow-left {
            margin-left: 0;
            @include rem(margin-right, $space);
        }
    }


    .button--square {
        @include rem(width, $space*1.5);
        @include rem(height, $space*1.5);
        @include rem(padding, $space-xs);
    }

    .button--square-arrow-r {
        // box-shadow: none;
        // background: 
        //     linear-gradient(rgba(color(brand), .5), rgba(color(brand), .5)) top left    / #{$space*1.5} 1px             no-repeat, // top
        //     linear-gradient(rgba(color(brand), .5), rgba(color(brand), .5)) top left    / 1px           100%            no-repeat, // left
        //     linear-gradient(rgba(color(brand), .5), rgba(color(brand), .5)) bottom left / #{$space*1.5} 1px             no-repeat, // bottom
        //     linear-gradient(
        //         45deg, 
        //         transparent 47.5%, 
        //         rgba(color(brand), .5) 47.5%, 
        //         rgba(color(brand), .5) 52.5%,
        //         transparent 52.5%
        //     ) top right / #{$space*.75} #{$space*.75}   no-repeat, // top right
        //     linear-gradient(
        //         -45deg, 
        //         transparent 47.5%, 
        //         rgba(color(brand), .5) 47.5%, 
        //         rgba(color(brand), .5) 52.5%,
        //         transparent 52.5%
        //     ) bottom right / #{$space*.75} #{$space*.75}   no-repeat; // bottom right
        // @include rem(width, $space*2.25);
        // @include rem(padding-right, $space);

        &:hover {

        }
    }

    .button--filter { // button with settings icon
        font-size: 0;
        background: 
            radial-gradient(
                color(brand) 25%,
                color(blank) 25%,
                color(blank) 50%,
                transparent  50%
            ) no-repeat top #{$space/8} left #{$space-xs} / $space-s $space-s,
            radial-gradient(
                color(brand) 25%,
                color(blank) 25%,
                color(blank) 50%,
                transparent  50%
            ) no-repeat center right #{$space-xs} / $space-s $space-s,
            radial-gradient(
                color(brand) 25%,
                color(blank) 25%,
                color(blank) 50%,
                transparent  50%
            ) no-repeat bottom #{$space/8} left #{$space-xs} / $space-s $space-s,
            linear-gradient(
                color(blank) 10%,
                transparent  10%,
                transparent  45%,
                color(blank) 45%,
                color(blank) 55%,
                transparent  55%,
                transparent  90%,
                color(blank) 90%
            ) no-repeat center / $space $space*7/8,
            linear-gradient(
                color(brand),
                color(brand)
            )
        ;
    }

    .button--close {
        font-size: 0;
        box-shadow: none;
        background: 
            linear-gradient(
                45deg,
                transparent  45%,
                color(brand-sec) 45%,
                color(brand-sec) 55%,
                transparent  55%
            ) no-repeat center / $space $space,
            linear-gradient(
                -45deg,
                transparent  45%,
                color(brand-sec) 45%,
                color(brand-sec) 55%,
                transparent  55%
            ) no-repeat center / $space $space,
            linear-gradient(
                color(blank),
                color(blank)
            )
        ;
    }


/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(desk){
         
        // default button
        .button {
            transition: 
                color            $transition-hide,
                background-color $transition-hide;

            &:hover:not(:active) {
                color: color(blank);
                transition: 
                    color            $transition-show,
                    background-color $transition-show;

            }
        }

        // base color outline button
        .button--base {

            &:hover:not(:active) {
                background-color: color(base);
            }
        }

        // brand color outline button
        .button--brand {

            &:hover:not(:active) {
                background-color: color(brand);
            }
        }

        // blank color outline button
        .button--blank {

            &:hover:not(:active) {
                background-color: color(blank);
                color: color(base);
            }
        }

        // button with arrow on the right
        .button--has-arrow {

            // the arrow image
            .button__arrow {
                transition: fill $transition-hide;
            }

            &:hover:not(:active) {

                // the arrow image
                .button__arrow {
                    fill: color(blank);
                    transition: fill $transition-show;
                }
            }

            // blank button with arrow on the right
            &.button--blank {

                &:hover:not(:active) {

                    // the arrow image
                    .button__arrow {
                        fill: color(base);
                    }
                }
            }
        }
    }