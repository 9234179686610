$grid-gap: 16px;

.contact-form {
  label {
    margin-bottom: 8px;
  }

  input[type='text'], input[type='email'], textarea {
    box-shadow: unset !important;
    outline: 1px solid #34495E;
    margin: unset;
  }

  select {
    margin-bottom: unset !important;
  }

  button[type='submit'] {
    @extend .button;
    @extend .button--brand;

    background-color: #EFEFEF !important;
  }

  .form-grid {
    display: flex;
    flex-direction: column;
    gap: $grid-gap;

    @include media-query(gt-palm) {
      display: grid;
      gap: $grid-gap;
      grid-template-columns: repeat(3, 1fr);
    }

    .form-grid__row-full {
      display: grid;
      grid-template-columns: 1fr;
      grid-column: span 3;
    }
  }
}