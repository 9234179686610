/*----------------------------------------------------------------------------*\
    MASTHEAD
    Top of page
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $header-height-lt-desk: 3;
    $header-height-desk: 5.5;

    $hero-square-offset-lt-desk: 2;
    $hero-square-offset-desk: 5;

/*  Component
\*----------------------------------------------------------------------------*/

    .masthead {}

        // floating top bar
        .masthead__header { 
            position: fixed;
            z-index: 9999;
            width: 100%;
            font-size: 0;
            background-color: color(blank);
            transition: box-shadow $transition;
            box-shadow: 2px 2px transparent;
            @include rem(padding-top, $space-s);
            @include rem(height, $space*$header-height-lt-desk);
            @include rem(max-width, $site-width-l);

            body[data-state=scrolled] & {
                box-shadow: 0 2px rgba(color(base), .25);
            }
        }

        // logo link
        .masthead__brand-link {
            position: relative;
            z-index: 1;
            float: left;
            display: block;
            @include rem(padding, $space-s);
            @include rem(margin, 0 $space-s*-1);
            @include rem(top, -$space-s);
        }
        
        // logo
        .masthead__brand-image {
            display: block;
            @include rem(height, $space*2);
        }

        // menu button with hamburger icon
        .masthead__nav-toggle {
            position: relative;
            display: block;
            float: right;
            border: 0;
            background: transparent;
            color: color(brand);
            transition: color $transition-hide;
            @include rem(border-radius, $space/12); 
            @include font-size($fontsize-s, .5);
            @include rem(width, $space-l);
            @include rem(height, $space-l);

            // menu button inner 
            span {
                display: block;
                @include rem(padding, $space 0 0 0);
            }

            // icon lines 
            &:before,
            &:after,
            span:before,
            span:after {
                content: '';
                background: color(brand);
                position: absolute;
                height: 1px;
                @include rem(width, $space);
                @include rem(left, $space-s);
                @include rem(top, $space*3/4);
            }

            // icon top & bottom lines 
            &:before,
            &:after {
                transition: transform $transition-hide;
            }
            
            // icon top line 
            &:before {
                transform: translateY(#{valueToRem(-$space/4)});
            }

            // icon bottom line
            &:after {
                transform: translateY(#{valueToRem($space/4)});
            }
            
            // icon center line
            span:before,
            span:after {
                transition: transform $transition-hide;
            }

            &:focus {
                outline: none;
                box-shadow: inset 0 0 0 1px rgba(color(brand), .5); 
            }

            &:active {
                background-color: color(brand);
                color: color(blank);
                transform: translateY(1px);
                transition: none;

                // icon lines 
                &:before,
                &:after,
                span:before,
                span:after {
                    background: color(blank);
                }
            }

            // button when toggled
            &[data-state=active] {
                
                // icon top & bottom lines 
                &:before,
                &:after {
                    transition: transform $transition-show;
                }
                    
                // icon top line 
                &:before {
                    transform: translateY(#{valueToRem(-$space/4)}) scale(0.001);
                }

                // icon bottom line
                &:after {
                    transform: translateY(#{valueToRem($space/4)}) scale(0.001);
                }

                // icon center lines
                span:before,
                span:after {
                    transition: transform $transition-show;
                }
                span:before {
                    transform: rotate(45deg);
                }
                span:after {
                    transform: rotate(-45deg);
                }
            }
        }
        
        // navigation positioning
        .masthead__nav { 
            position: fixed;
            display: block !important;
            overflow: hidden;
            text-align: right;
            width: 100%;
            left: 0;
            transform: translateX(calc(100% + #{$space}));
            transition: transform $transition-hide;
            background-color: rgba(color(blank), .9);
            border-bottom: 1px solid color(brand);
            box-shadow: 0 $space-s $space-s rgba(color(base), .25);
            overflow: auto;
            max-height: calc(100vh - #{valueToRem($space*3)});
            @include rem(top, $header-height-lt-desk*$space);

            // navigation when toggled
            &[data-state=active] {
                transition: transform $transition-show;
                transform: none;
            }
        }

        // language switcher position 
        .masthead__nav-lang {
            position: relative;
        }

        // eye catcher at top of page
        .masthead__hero {
            position: relative;
            font-size: 0;
            @include rem(top, $space*$header-height-lt-desk);
            @include rem(margin-bottom, $space*$header-height-lt-desk);
            @include rem(padding, $space 0);
        }

        .masthead__hero-inner {
            position: relative;
            margin: 0;

            // square red overlay
            &:before{
                content: '';
                position: absolute;
                z-index: 0;
                width: calc(100% - #{valueToRem($space*$hero-square-offset-lt-desk)});
                left: 0;
                height: 0;
                padding-bottom: calc(100% - #{valueToRem($space*$hero-square-offset-lt-desk)});
                background: color(brand);
                @include rem(top, $space*$hero-square-offset-lt-desk);
                
            }
        }

        // text in eye catcher
        .masthead__intro {}

        // headings in eye catcher text
        .masthead__intro-header {
            position: absolute;
            z-index: 1;
            color: color(blank);
            width: calc(100% - #{valueToRem($space*$hero-square-offset-lt-desk)});
            bottom: calc(100% - 100vw - #{valueToRem($space*($hero-square-offset-lt-desk - $header-height-lt-desk))});
            @include rem(padding, $space $space-s);
        }

        // hr below headings
        .masthead__line {
            background-color: color(blank);
            margin-bottom: 0;
        }

        // image in eye catcher
        .masthead__visual {
            position: relative;
            z-index: 1;
            width: calc(100% - #{valueToRem($space*$hero-square-offset-lt-desk)});
            display: inline-block;
            vertical-align: top;
            top: 0;
            height: 0;
            padding-bottom: calc(100% - #{valueToRem($space*$hero-square-offset-lt-desk)});
            background-size: cover;
            background-position: center;
            mix-blend-mode: multiply; // enable this for initial design
            @include rem(margin-left, $space*$hero-square-offset-lt-desk);
            @include rem(margin-bottom, $space*($hero-square-offset-lt-desk+1));
        }

    /*  Eye catcher wide variant
    \*----------------------------------------------------------------------------*/
        
        // .masthead__hero--wide {
        //     background-color: color(base);

        //     .masthead__hero-inner {
        //         position: static;
        //         direction: ltr;
        //         @include rem(padding, $space 0);

        //         // gradient overlay
        //         &:before {
        //             position: absolute;
        //             display: block;
        //             z-index: 1;
        //             top: 0;
        //             left: 0;
        //             width: 100%;
        //             height: 100%;
        //             padding: 0;
        //             background: linear-gradient(
        //                 90deg,
        //                 color(base),
        //                 transparent
        //             ) top left / 200% 100%;
        //             @include rem(bottom, $space*$header-height-lt-desk);
        //         }
        //     }

        //     .masthead__visual {
        //         position: absolute;
        //         z-index: 0;
        //         top: 0;
        //         left: 0;
        //         width: 100%;
        //         height: 100%;
        //         margin: 0;
        //         padding: 0 50%;
        //         mix-blend-mode: normal;
        //     }

        //     .masthead__intro {
        //         color: color(blank);
        //         position: relative;
        //         z-index: 2;
        //         width: 100%;
        //         margin: 0;
        //         padding: 0;
        //     }

        //     .masthead__intro-header {
        //         position: relative;
        //         padding: 0;
        //     }
        // }

    /*  Masthead without hero
    \*----------------------------------------------------------------------------*/

        .masthead--nohero {
            
            + .content {
                position: relative;
                @include rem(top, $space*$header-height-lt-desk);
                @include rem(margin-bottom, $space*$header-height-lt-desk);
            }
        }


/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(gt-palm){

        .masthead {}

            // eye catcher at top of page
            .masthead__hero {
                @include rem(top, $space*$header-height-desk);
                @include rem(margin-bottom, $space*$header-height-desk);
                @include rem(padding, $space*2 0);
            }

            .masthead__hero-inner {
                direction: rtl; // text left, image right

                // square red overlay
                &:before{
                    content: none;
                    // width: calc(50% - #{valueToRem($space-s)});
                    // left: auto;
                    // right: 0;
                    // padding-bottom: calc(50% - #{valueToRem($space-s)});
                    // @include rem(top, $space*$hero-square-offset-desk);
                }
            }

            // text in eye catcher
            .masthead__intro {
                width: calc(50% - #{valueToRem($space-s)});
                display: inline-block;
                vertical-align: top;
                direction: ltr;
                @include rem(padding-top, $space*$hero-square-offset-desk);
                @include rem(margin-right, $space-s);
            }

            // headings in eye catcher text
            .masthead__intro-header {
                position: relative;
                color: color(base);
                padding: 0;
                top: 0;
                @include rem(margin-bottom, $space);
            }

            // 
            .masthead__intro-subheading {
                color: color(brand-sec);
                @include font-size($fontsize-l, .75);
            }

            // hr below headings
            .masthead__line {
                background-color: color(brand);
            }

            // image in eye catcher
            .masthead__visual {
                position: relative;
                width: calc(50% - #{valueToRem($space*($hero-square-offset-desk+.5))});
                padding-bottom: calc(50% - #{valueToRem($space*($hero-square-offset-desk+.5))});
                mix-blend-mode: normal; // disable this for initial design
                right: 0;
                box-shadow: -#{valueToRem($space*$hero-square-offset-desk)} #{valueToRem($space*$hero-square-offset-desk)} 0 color(brand);
                // @include rem(right, $space*-$hero-square-offset-desk);
                @include rem(margin-left, $space*($hero-square-offset-desk+.5));
                @include rem(margin-bottom, $space*$hero-square-offset-desk);
            }

                .masthead__visual-inner {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                    .masthead__visual-image {
                        width: 100%;
                        height: 100%;    
                    }
    }

    @include media-query(desk){

        .masthead {}

            // floating top bar
            .masthead__header { 
                @include rem(padding-top, $space*1.5);
                @include rem(height, $space*$header-height-desk);
            }

            .masthead__brand-image {
                height: auto;
                @include rem(width, 6.75*$space);
            }

            // hamburger icon
            .masthead__nav-toggle {
                display: none;
            }

            // navigation positioning
            .masthead__nav { 
                position: relative;
                width: auto;
                background-color: transparent;
                box-shadow: none;
                border: 0;
                overflow: visible;
                @include rem(top, $space*-1.5);
                @include rem(padding-top, $space*1.5);
    
                // override positioning 
                &,
                &[data-state=active] {
                    transition: none;
                    transform: none;
                }
            }

            // language switcher position 
            .masthead__nav-lang {
                position: absolute;
                z-index: 2;
                top: 0;
                right: 0;
                display: block;
                @include font-size($fontsize-m);
            }
            


        /*  Eye catcher wide variant
        \*----------------------------------------------------------------------------*/
            
            .masthead__hero--wide {

                .masthead__hero-inner {
                    @include rem(padding, $space*5 0);

                    &:before {
                        background-size: 100%;
                    }
                }

                .masthead__intro {
                    width: 50%;
                }

                // headings in eye catcher text
                .masthead__intro-header,
                .masthead__intro-subheading {
                    color: color(blank);
                }
            }

        /*  Masthead without hero
        \*----------------------------------------------------------------------------*/
            
            .masthead--nohero {
                    
                + .content {
                    @include rem(top, $space*$header-height-desk);
                    @include rem(margin-bottom, $space*$header-height-desk);
                }
            }
    }