/*----------------------------------------------------------------------------*\
    ICON
    .icon
    wiki - https://git.nobears.nl/NoBearcss/nobearcss/wikis/Icons
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $percentages: '25', '50', '75', '100';

    @mixin icon--percentage--interact {
        color: color(base);
        background-size: 100% 100%, 100% 100%;
    }



/*  Component
\*----------------------------------------------------------------------------*/

    // default square icon
    .icon {
        fill: color(base);
        @include rem(width, $space);
        @include rem(height, $space);
    }

    .icon--s {
        @include rem(width, $space-s);
        @include rem(height, $space-s);
    }

    .icon--l {
        @include rem(width, $space-l);
        @include rem(height, $space-l);
    }

    .icon--percentage {
        display: inline-block;
        vertical-align: top;
        text-align: center;
        font-weight: 600;
        cursor: pointer;
        color: transparent;
        border-radius: 50%;
        background: radial-gradient(
            color(blank) 25%,
            transparent 25%
        ) color(base-med);

        &:focus {
            @include icon--percentage--interact;
        }
    }

    // @each $percentage in $percentages {

    //     .icon--percentage--#{$percentage} {
    //         background: 
    //             radial-gradient( /* centered white circle */
    //                 color(blank) 50%,
    //                 transparent 50%
    //             ) center / 50% 50% no-repeat,
    //             conic-gradient( /* colored circle part */
    //                 color(percentage-#{$percentage}) #{$percentage+'%'},
    //                 transparent #{$percentage+'%'}
    //             ) center / 100% 100%
    //             color(base-med) /* background */;
    //     }    
    // }

    .icon--percentage--25 {
        background: 
        radial-gradient( /* centered white circle */
            color(blank) 50%,
            transparent 50%
        ) center / 50% 50% no-repeat,
        linear-gradient( /* colored circle part */
            color(percentage-25),
            color(percentage-25)
        ) no-repeat top right / 50% 50%
        color(base-med) /* background */;
    }

    .icon--percentage--50 {
        background: 
        radial-gradient( /* centered white circle */
            color(blank) 50%,
            transparent 50%
        ) center / 50% 50% no-repeat,
        linear-gradient( /* colored circle part */
            color(percentage-50),
            color(percentage-50)
        ) no-repeat top right / 50% 100%
        color(base-med) /* background */;
    }

    .icon--percentage--75 {
        background: 
        radial-gradient( /* centered white circle */
            color(blank) 50%,
            transparent 50%
        ) center / 50% 50% no-repeat,
        linear-gradient( /* colored circle part */
            color(percentage-75),
            color(percentage-75)
        ) no-repeat top right / 50% 100%,
        linear-gradient( /* colored circle part */
            color(percentage-75),
            color(percentage-75)
        ) no-repeat bottom left / 100% 50%
        color(base-med) /* background */;
    }

    .icon--percentage--100 {
        background: 
        radial-gradient( /* centered white circle */
            color(blank) 50%,
            transparent 50%
        ) center / 50% 50% no-repeat
        color(percentage-100) /* background */;
    }


    .icon--pointer {
        font-size: 0;
        width: 0;
        height: 0;
        border-style: solid;
        display: inline-block;
        vertical-align: top;
    }

    .icon--pointer-l {
        border-color: transparent color(brand) transparent transparent;
        @include rem(border-width, $space/2 $space/1.5 $space/2 0);
    }

    .icon--pointer-r {
        border-color: transparent transparent transparent color(brand);
        @include rem(border-width, $space/2 0 $space/2 $space/1.5);
    }



/*  Media queries
\*----------------------------------------------------------------------------*/
    
    @include media-query(desk){
         
        .icon--percentage {
            transition: 
                color $transition,
                background-size $transition;

            &:hover {
                @include icon--percentage--interact;
            }
        }
    }
        
