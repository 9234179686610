/*----------------------------------------------------------------------------*\
    Fixed ratio
    .fixedratio

\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $use-fixedratio: true !default;

/*  Component
\*----------------------------------------------------------------------------*/

    @if $use-fixedratio == true {

        .fixedratio {
            position: relative;
            overflow: hidden;
            height: 0;
            padding: 0;
            padding-bottom: 100%;
        }
        
        .fixedratio__item,
        .fixedratio iframe,
        .fixedratio embed,
        .fixedratio object {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        
        // 16:9
        .fixedratio--16x9 {
            padding-bottom: 56.25%;
        }

        // 4:3
        .fixedratio--4x3 {
            padding-bottom: 75%;
        }

    }