/*----------------------------------------------------------------------------*\
    PAGINATION
    for multipage overviews
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .paginator {
        margin: 0;
        list-style: none;
        font-size: 0;
        text-align: center;
    }

        .paginator__item {
            display: none;
            position: relative;
            @include rem(margin-right, $space-s);

            &.active a {
                background: color(brand);
                color: color(blank);
            }
        }

        .paginator__item--first,
        .paginator__item--previous {
            @include rem(padding-right, $space-s);

            &:after {
                right: 0;
            }
        }

        .paginator__item--last,
        .paginator__item--next {
            margin-right: 0;
            @include rem(padding-left, $space-s);

            &:before {
                left: 0;
            }
        }

        .paginator__item--first:after,
        .paginator__item--previous:after,
        .paginator__item--last:before,
        .paginator__item--next:before {
            content: '';
            position: absolute;
            top: 0;
            height: 100%;
            width: 1px;
            background-color: rgba(color(brand), .5);
        }

        .paginator__item.active,
        .paginator__item--previous,
        .paginator__item--next {
            display: inline-block;
        }



/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(gt-palm){
         
        .paginator__item {
            display: inline-block;
        }

        .paginator__item--previous,
        .paginator__item--next {
            display: none;
        }
    }