/*----------------------------------------------------------------------------*\
    MIXINS
    Default mixins

\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $browser-context: $base-font-size !default;
    $legacy-support-for-ie8: false !default;

/*  Functions
\*----------------------------------------------------------------------------*/

    // VALUE TO REM
    @function valueToRem($value) {
        @if type-of($value) == string or unitless($value) == true or unit($value) == "em" or unit($value) == "%" {
            @return $value;
        } @else {
            @return ($value/$browser-context*1rem);
        }
    }


    // VALUE TO EM
    @function valueToEm($value, $context: $browser-context) {
        @return ($value/$context*1em);
    }


    // REMOVE UNITS
    @function strip-units($number) {
        @return $number / ($number * 0 + 1);
    }

    // COLOR
    @function color($key) {
        @if map-has-key($colors, $key) {
            @return map-get($colors, $key);
        }

        @error "Unknown `#{$key}` in $colors.";
        @return null;
    }

    @each $color, $value in $colors{
        
        .bg--#{$color} {
            background-color: $value !important;
        }
        .color--#{$color} {
            color: $value !important;
        }
        .fill--#{$color} {
            fill: $value !important;
        }
    }

/*  Mixins
\*----------------------------------------------------------------------------*/

    // REM CONVERTER
    @mixin rem ($property, $value, $append: "") {
        @if $legacy-support-for-ie8 == true {
            #{$property}: $value #{$append};
        }

        @if type-of($value) == list {
            $all: ();
            @for $i from 1 through length($value) {
                $all: append($all, valueToRem(nth($value, $i)));
            }
            #{$property}: $all #{$append};
        } @else {
            #{$property}: valueToRem($value) #{$append};
        }
    }

    // FONT SIZE
    @mixin font-size($fs, $line-height: true, $multiplier: 1) {
        @include rem(font-size, $fs);

        $use-line-height: false;

        @if type-of($line-height) == bool {
            @if $line-height == true{
                $use-line-height: true;
            }
        } @else if type-of($line-height) == number {
            $use-line-height: true;
            $multiplier: $line-height;
        }

        @if $use-line-height == true {
            line-height: (ceil($fs / $base-line-height) * ($base-line-height / $fs)) * $multiplier;
        }
    }

    // TRUNCATE
    @mixin truncate($truncation-boundary: 100%) {
        @include rem(max-width, $truncation-boundary);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    // MEDIA QUERIES
    @mixin media-query($media-query) {
        $breakpoint-found: false;

        @each $breakpoint in $breakpoints {
            @if type-of($breakpoint) == 'list' {
                $name: nth($breakpoint, 1);
                $declaration: nth($breakpoint, 2);

                @if $media-query == $name and $declaration {
                    $breakpoint-found: true;

                    @media only screen and #{$declaration} {
                        @content;
                    }
                }
            } @else {
                @error "Breakpoint doesn’t match the `'key': '(value)'` format";
            }
        }

        @if $breakpoint-found == false {
            @warn "Breakpoint ‘#{$media-query}’ does not exist";
        }
    }

    // HIDE ELEMENTS
    @mixin visuallyhidden {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }