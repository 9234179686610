/*----------------------------------------------------------------------------*\
    DIMENSIONS
    .unit
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $dimensions-use-units: true !default;
    $dimensions-use-push: false !default;
    $dimensions-use-pull: false !default;

    // COLUMNS
    $dimensions-divisions: 12 !default;

    // BREAKPOINTS
    $dimensions-breakpoint-has-units: () !default;
    $dimensions-breakpoint-has-push: () !default;
    $dimensions-breakpoint-has-pull: () !default;

/*  Mixins & functions
\*----------------------------------------------------------------------------*/

    @function gcf($a, $b) {
        @if $b == 0 { @return $a; }
        @else { @return gcf($b, $a % $b); }
    }

    @function contains($list, $value) {
        @if type-of($list) == list { @return not not index($list, $value); }
        @else { @return $list == $value; }
    }

    @mixin dimensions-build($units, $prefix: 'unit', $modifier: '') {

        @each $n in $units {
            /* Avoid creating rules like '.unit-12-12 {}'' */
            $x: $n - 1;

            @for $i from 1 through $x {
                /* Initialize variables */
                $i-r: ();
                $n-r: ();

                /* Find the greatest common factor */
                $g: gcf($i, $n);

                @if $g > 1 {
                    /* Reduced value of $i */
                    $i-r: $i/$g;
                    /* Reduced value of $n */
                    $n-r: $n/$g;
                }

                /**
                * Check if the reduced value of $n was also supplied in the
                * list of units to be built
                */
                $canreduce: contains($units, $n-r);

                /**
                * Create units based on fractions
                */
                .#{$prefix}-#{$i}-#{$n}#{$modifier} {
                    /**
                    * If this unit can be reduced then extend the previous rule
                    * 1. Use `flex-basis: auto` with a width to avoid box-sizing bug in IE10/11
                    */
                    @if $i-r and $canreduce {
                        @extend .#{$prefix}-#{$i-r}-#{$n-r}#{$modifier};
                    } @else {
                        @if $prefix == 'unit' {
                            @if $use-flexbox == true {
                                flex-basis: auto !important; /* [1] */
                            }
                            width: percentage($i / $n) !important;
                        } @else if $prefix == 'push' {
                            left: percentage($i / $n) !important;
                        } @else if $prefix == 'pull' {
                            right: percentage($i / $n) !important;
                        }
                    }
                }

            }
        }

        @if $prefix == 'unit' {
            .#{$prefix}-1-1#{$modifier} {
                @if $use-flexbox == true {
                    flex-basis: auto !important; /* [1] */
                }
                width: 100% !important;
            }
            .#{$prefix}-auto#{$modifier} {
                @if $use-flexbox == true {
                    flex-basis: auto !important; /* [1] */
                }
                width: auto !important;
            }
        } @else if $prefix == 'push' {
            .#{$prefix}-0-0#{$modifier} {
                left: 0 !important;
            }
        } @else if $prefix == 'pull' {
            .#{$prefix}-0-0#{$modifier} {
                right: 0 !important;
            }
        }
    }

/*  Component
\*----------------------------------------------------------------------------*/

    // CORE
    @if $dimensions-use-units == true {
        @include dimensions-build($dimensions-divisions);
    }

    // PUSH
    @if $dimensions-use-push == true {
        [class*="#{'push'}-"] {
            position:relative;
        }
        @include dimensions-build($dimensions-divisions, 'push');
    }

    // PULL
    @if $dimensions-use-pull == true {
        [class*="#{'pull'}-"] {
            position:relative;
        }
        @include dimensions-build($dimensions-divisions, 'pull');
    }

    // RESPONSIVE
    @if $dimensions-use-units == true {
        @each $name in $dimensions-breakpoint-has-units {
            @include media-query($name) {
            @include dimensions-build($dimensions-divisions, 'unit', '--#{$name}');
            }
        }
    }

    @if $dimensions-use-push == true {
        @each $name in $dimensions-breakpoint-has-push {
            @include media-query($name) {
            @include dimensions-build($dimensions-divisions, 'push', '--#{$name}');
            }
        }
    }

    @if $dimensions-use-pull == true {
        @each $name in $dimensions-breakpoint-has-pull {
            @include media-query($name) {
            @include dimensions-build($dimensions-divisions, 'pull', '--#{$name}');
            }
        }
    }
