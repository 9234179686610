/*----------------------------------------------------------------------------*\
    MODAL
    initially hidden overlay
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .modal {
    }

    .modal--palm {
        display: block !important;
        opacity: 0;
        pointer-events: none;
        transform: translateY(#{valueToRem($space)});
        background-color: color(brand-sec);
        position: fixed;
        z-index: 9999;
        width: calc(100% - #{valueToRem($space)});
        height: calc(100% - #{valueToRem($space*4)});
        transition: 
            transform $transition-hide,
            opacity   $transition-hide;
            overflow: auto;
        box-shadow: 0 0 $space-xs rgba(color(base), .5);
        @include rem(top, $space*3.5);
        @include rem(left, $space*.5);
        @include rem(padding, $space*2 $space-s $space-s);

        &[data-state=active] {
            opacity: 1;
            pointer-events: all;
            transform: none;
            transition: 
                transform $transition-show,
                opacity   $transition-show;
        }
    }

    .modal__close {
        font-size: 0;
        position: absolute;
        border: 0;
        @include rem(top, $space-s);
        @include rem(right, $space-s);
    }



/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(lap){

        .modal--palm {
            @include rem(padding, $space*1.5 0 0 0);
        }
    }

    @include media-query(gt-palm){
         
        .modal--palm {
            opacity: 1;
            pointer-events: all;
            position: relative;
            z-index: auto;
            width: auto;
            top: auto;
            left: auto;
            transform: none;
            box-shadow: none;

            .modal__close {
                display: none;
            }
        }
    }

    @include media-query(desk){

        .modal--palm {
            @include rem(padding, $space*2 0 0 0);
        }
    }
