/*----------------------------------------------------------------------------*\
    FIXED RATIO
    extends nobearcss fixedratio
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .fixedratio--image {
        display: block;
        width: 100%;
        background-size: cover;
        background-position: center;
    }