/*----------------------------------------------------------------------------*\
    MAIN MENU
    Main navigation, preferably first in the HTML
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .nav--main{
        text-align: right;
        @include rem(padding, 0 $space-s $space);

        // list item
        .nav__item{
            position: relative;
            vertical-align: top;
            display: block;
        }

        // anchor
        .nav__link{
            display: block;
            color: color(base);
            font-weight: 700;
            text-decoration: none;
            text-transform: uppercase;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            @include font-size($base-font-size);
            @include rem(padding, $space-s !important);
            @include rem(margin-right, $space);

            &:active{
                color: color(brand-dark);
            }
        }

        // show/hide submenu
        .nav__subtoggle {
            position: absolute;
            outline: 0;
            top: 0;
            right: 0;
            display: none;
            background: transparent;
            border: 0;
            transform: rotate(90deg);
            padding: 0;
            transition: transform $transition-hide;
            @include rem(width, $space);
            @include rem(height, $space*2);

            &:active {

                svg {
                    fill: color(brand);
                }
            }

            &[data-state=active]{
                transition: transform $transition-show;
                transform: rotate(180deg);
            }
        }

        .nav__item--has-submenu {

            > .nav__subtoggle {
                display: block;
            }
        }

        .nav__item.is--active{

            > .nav__link {
                color: color(brand);
            }
        }

        .nav__sub {
            margin: 0;
            display: block;
        }

        .nav__sub-item {
            height: 0;
            overflow: hidden;
            transition: height $transition-hide;
        }

        .nav__sub[data-state=active] .nav__sub-item{
            transition: height $transition-show;
            @include rem(height, $space*2);
        }


        .nav__sub-link {
            font-weight: 400;
            @include rem(margin-right, $space*2.5);
        }

        // show/hide submenu
        .nav__subsubtoggle {
            position: absolute;
            outline: 0;
            top: 0;
            display: none;
            background: transparent;
            border: 0;
            transform: rotate(90deg);
            transition: transform $transition-hide;
            @include rem(right, $space);
            @include rem(width, $space*2);
            @include rem(height, $space*2);

            &[data-state=active]{
                transition: transform $transition-show;
                transform: rotate(180deg);
            }
        }

        .nav__sub-item--has-subsubmenu > .nav__subsubtoggle {
            display: block;
        }

        .nav__subsub {
            margin: 0;
            display: none;

            &[data-state=active]{
                display: block;
            }
        }

        .nav__subsub-link {
            font-weight: 400;
            color: rgba(color(base), .5);
            @include rem(margin-right, $space*2.5);
        }

        // button-like menu item
        .nav__item--alt {
            position: relative;
            @include rem(margin-right, $space-s);
            @include rem(top, $space-s);

            .nav__link {
                @extend .button;
                @extend .button--brand;
                @extend .button--light;
            }
        }

        .nav__preview {
            display: none;
        }
    }




/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(desk){

        .nav--main {
            padding: 0;

            // list item
            .nav__item{
                height: auto;
                position: static;
                display: inline-block;
            }

            // anchor
            .nav__link {
                margin: 0;
                @include rem(padding, $space*3/4 $space !important);

                &:hover:not(:active){
                    color: color(brand);
                }
            }

            .nav__item:last-child .nav__link {
                padding-right: 0 !important;
            }

            .nav__subtoggle {
                // display: none !important;
                position: relative;
                left: calc(50% - #{valueToRem($space-s)});
                border-radius: 50%;
                background-color: color(blank);
                opacity: 0;
                transition:
                    opacity   $transition-hide;
                @include rem(width, $space);
                @include rem(height, $space);
                @include rem(top, $space);

            }

            // button-like menu item
            .nav__item--alt {
                top: 0;
                margin: 0;
            }

            .nav__item--has-submenu {
                @include rem(height, $space*4.5);

                &:after{
                    content: '';
                    opacity: 0;
                    position: fixed;
                    z-index: -1;
                    background: color(base);
                    width: 100%;
                    left: 50%;
                    transform: translateX(-50%) scaleY(0.0001);
                    transform-origin: top;
                    transition:
                        transform $dur-hide $transition-hide,
                        opacity   0s $dur-hide*2 linear;
                    @include rem(top, $space*5.5);
                    @include rem(height, $space*17.5);
                    @include rem(max-width, $site-width-l);
                }

                &:hover {

                    .nav__subtoggle {
                        opacity: 1;
                        transition:
                            opacity   $transition-show;
                    }

                    &:after{
                        opacity: 1;
                        transform: translateX(-50%);
                        transition:
                            transform $transition-show,
                            opacity   0s;
                    }
                }
            }

            .nav__sub {
                opacity: 0;
                position: fixed;
                z-index: 2;
                height: 0;
                overflow: hidden;
                left: 0;
                margin: 0;
                width: 100%;
                text-align: left;
                transition:
                    opacity $transition-hide,
                    height  $dur-hide $transition-hide,
                    padding $dur-hide $transition-hide,
                    z-index $dur-hide 0s linear;
                left: 50%;
                transform: translateX(-50%);
                @include rem(max-width, $site-width - $space);
            }

            .nav__sub-item {
                display: block;
                //width: percentage(1/3);
                width: 100%;
                height: auto !important;
                // transition:

                &:hover {
                    // background: rgba(color(base-med), .25);

                    & > .nav__subsub {
                        // display: block;
                        pointer-events: all;
                    }

                    .nav__sub-link:after {
                        // opacity: 1;
                        transform: none;
                        transition:
                            transform $transition-show;
                    }

                    .nav__subsub-link{
                        transition:
                            width $dur-show $transition-show;
                        @include rem(width, $site-width/3);
                    }
                }
            }

            // anchor
            .nav__sub-link {
                position: relative;
                z-index: 1;

                &,
                &:hover {
                    color: color(blank) !important;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: calc(100% - #{valueToRem($space*2/3)});
                    background: rgba(color(base-med), .25);
                    transform: scaleX(0.0001);
                    transform-origin: 0;
                    transition:
                        transform $transition-hide;
                }
            }

            .nav__subsubtoggle {
                display: none;
            }

            .nav__item:hover > .nav__sub {
                z-index: 1;
                opacity: 1;
                transition:
                    opacity $dur-show $transition-show,
                    height  $transition-show,
                    padding $transition-show,
                    z-index $dur-show 0s linear;
                @include rem(height, $space*17.5);
                @include rem(padding-top, $space*2.5);
                @include rem(padding-bottom, $space);

                .nav__sub-item {
                }
            }

            .nav__subsub {
                display: block;
                position: absolute;
                top: 0;
                margin: 0;
                left: calc(50% - #{valueToRem($site-width/6)});
                // transform: translateX(-50%);
                @include rem(padding, $space*2.5 0 $space);
                pointer-events: none;
            }

            .nav__subsub-item {
                display: block;
                @include rem(width, $site-width/3);

                &:hover {
                    // background: rgba(color(base-med), .25);
                    // transition:
                    //     background $transition-show;

                    & > .nav__preview {
                        width: 100%;
                        transition: width $dur-show $transition-show;
                    }

                    .nav__subsub-link:after {
                        transform: none;
                        transition:
                            transform $transition-show;

                        // opacity: 1;
                        // transition: opacity $transition-show;
                    }
                }
            }

            // anchor
            .nav__subsub-link {
                width: 0;
                position: relative;
                white-space: nowrap;
                overflow: hidden;
                transition:
                    width $transition-hide;
                padding-right: 0 !important;

                &,
                &:hover {
                    color: color(blank) !important;
                }

                &:after {
                    content: '';
                    // opacity: 0;
                    position: absolute;
                    top: 0;
                    height: 100%;
                    width: calc(100% - #{valueToRem($space*2/3)});
                    background: rgba(color(base-med), .25);
                    // transition: opacity $transition-hide;
                    transform: scaleX(0.0001);
                    transform-origin: 0;
                    transition:
                        transform $transition-hide;

                    @include rem(left, $space/3);
                }
            }

            .nav__preview {
                display: block;
                position: absolute;
                left: 100%;
                color: color(blank);
                width: 0;
                overflow: hidden;
                transition: width $transition-hide;
                @include rem(top, $space*2.5);
            }

            .nav__preview-inner {
                display: block;
                @include rem(width, $site-width/3);
                @include rem(padding-left, $space*2/3);
                // width: 100%;
                // width: calc(100% - #{valueToRem($space*2/3)});
            }
        }
    }
