/*----------------------------------------------------------------------------*\
    ASIDE
    Side of page
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

.aside {
    overflow:hidden;
}

.aside > a.button:last-of-type {
    margin-bottom: $space;
}