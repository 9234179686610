@charset "UTF-8";

@import "vars";
@import "../lib/nobearcss/starterkit";

// default framework modules
@import "../lib/nobearcss-arrange/arrange";
@import "../lib/nobearcss-grid/grid";
@import "../lib/nobearcss-layout/layout";
@import "../lib/nobearcss-dimensions/dimensions";
@import "../lib/nobearcss-fixedratio/fixedratio";
@import "../lib/nobearcss-island/island";
@import "../lib/nobearcss-buttons/buttons";
@import "../lib/nobearcss-helpers/helpers";
@import "../lib/nobearcss-island/island";
@import "../lib/nobearcss-form/form";
@import "../lib/nobearcss-nav/nav";
@import "../lib/nobearcss-icons/icons";
@import "../lib/nobearcss-image/image";
@import "../lib/nobearcss-toggle/toggle";

/** base folder */
@import "./theme/base/color";
@import "./theme/base/form";
@import "./theme/base/list";
@import "./theme/base/table";
@import "./theme/base/toplevel";
@import "./theme/base/typography";
@import "./theme/base/editmode";

/** layout folder */
@import "./theme/layout/aside";
@import "./theme/layout/footer";
@import "./theme/layout/layer";
@import "./theme/layout/masthead";

/** modules folder */
@import "./theme/modules/alert";
@import "./theme/modules/button";
@import "./theme/modules/card";
@import "./theme/modules/carousel";
@import "./theme/modules/cookiepopup";
@import "./theme/modules/fixedratio";
@import "./theme/modules/grid";
@import "./theme/modules/helpers";
@import "./theme/modules/icon";
@import "./theme/modules/modal";
@import "./theme/modules/sign";
@import "./theme/modules/slider";
@import "./theme/modules/squeeze";
@import "./theme/modules/tabs";
@import "./theme/modules/contactform";
@import "./theme/modules/leaflet";
@import "./theme/modules/sharelinks";
@import "./theme/modules/product";

/** nav folder */
@import "./theme/nav/breadcrumbs";
@import "./theme/nav/navfoot";
@import "./theme/nav/navicon";
@import "./theme/nav/navlang";
@import "./theme/nav/navmain";
@import "./theme/nav/paginator";
@import "./theme/nav/pagination";

/** general styling */
@import "./theme/styling";

/** external modules */
@import "../../node_modules/glightbox/dist/css/glightbox.css";