/*----------------------------------------------------------------------------*\
    LAYER
    Page part
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .layer{
        position: relative;
        @include rem(padding, $space 0);
    }

        .layer__image {
            position: relative;
            display: block;
            width: 100%;
            @include rem(top, $space);
        }



/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(gt-palm){

        .layer{}

            .layer__image {
                position: absolute;
                top: 0;
                right: 0;
                width: 0;
                height: 100%;
                padding: 0 0 0 50%;
                background-size: cover;
                background-position: center;

                .grid--rev > & {
                    left: 0;
                    right: auto;
                }
            }
    }

    @include media-query(desk){

        // final layer footer overlap
        .content > .layer:last-child,
        .content > *:last-child .layer {
            @include rem(padding-bottom, $space*4.25 !important);
        }

        .layer {
            @include rem(padding, $space-l 0);
        }
    }

    @include media-query(palm){

        .layer{
        }

            .layer__image {
                background-image: none !important;
            }
    }
