.share-links {
  display: flex;
  justify-content: center;
  gap: .8rem;

  .icon {
    color: #34495E;
    fill: #34495E;

    /** slight modifications to make everything alight nicely */
    &.icon--linkedin {
      transform: scale(0.9) translateY(-3px);
    }

    &.icon--facebook {
      transform: scale(0.9) translateY(-1px);
    }

    &.icon--twitter {
      transform: scale(0.85);
    }
  }
}