/*----------------------------------------------------------------------------*\
    FOOTER
    Bottom of page
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/
    
    .footer{
        background-color: color(base);
        color: color(blank);
    }

        // footer 'header' 
        .footer__top {
            @include rem(padding, $space*2.5 0 $space);
        }

            .footer__top-socmed {}

            .footer__top-nav {}

        // link to root
        .footer__brand {
            display: block;
            @include rem(margin-bottom, $space);
        }

        // footer icon row
        .footer__icons {
            overflow: hidden;
            text-align: right;
        }

        // social media icons
        .footer__icon {
            fill: color(blank) !important;

            &:active {
                fill: rgba(color(blank), .75);
            }
        }

        // default anchor in footer
        .footer__link {
            color: color(blank);
            text-decoration: none;
        }

        // .footer__smallink {
        //     @include font-size($fontsize-l);
        // }

        // main part of footer
        .footer__main {
            @include rem(padding, $space 0 $space-l);
        }
            
        // bottom part of footer
        .footer__bottom {
            @include rem(padding, $space 0 $space-xl);   
        }

        // horizontal row
        .footer__separator {
            border: 0;
            height: 1px;
            width: 100%;
            margin-bottom: -1px;
            background-color: rgba(color(blank), .2);
        }

        // title in footer
        .footer__heading {

            a {
                color: color(blank);
                text-decoration: none;
            }
            // @include rem(padding-bottom, $space-s);

            &:after {
                content: '';
                position: relative;
                display: block;
                background-color: rgba(color(blank), .2);
                @include rem(width, $space-l);
                @include rem(height, $space-xs);
                @include rem(top, $space-xs);
                @include rem(margin-bottom, $space*3/4);
            }
        }



/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(desk){
         
        .footer {
            position: relative;
            // border-top: #{valueToRem($space*4.25)} solid color(blank);

            // decorative footer squares
            &:before,
            &:after {
                content: '';
                position: absolute;
                mix-blend-mode: multiply;
                @include rem(top, $space*-3.25);
                @include rem(width, $space*15.5);
                @include rem(height, $space*6.5);
            }

            &:before {
                left: 0;
                background: 
                    linear-gradient(
                        rgba(color(base), .4),
                        rgba(color(base), .4)
                    ) center left / #{$space*6.5} #{$space*6.5} no-repeat,
                    linear-gradient(
                        color(base),
                        color(base)
                    ) center left #{$space*6.5} / #{$space*4} #{$space*4} no-repeat,
                    linear-gradient(
                        rgba(color(base), .4),
                        rgba(color(base), .4)
                    ) top left #{$space*10.5} / #{$space*3.25} #{$space*3.25} no-repeat,
                    linear-gradient(
                        color(base),
                        color(base)
                    ) center left #{$space*13.75} / #{$space*2} #{$space*2} no-repeat                    
                ;
            }

            &:after {
                right: 0;
                background: 
                    linear-gradient(
                        rgba(color(base), .4),
                        rgba(color(base), .4)
                    ) center right / #{$space*6.5} #{$space*6.5} no-repeat,
                    linear-gradient(
                        color(base),
                        color(base)
                    ) center right #{$space*6.5} / #{$space*4} #{$space*4} no-repeat,
                    linear-gradient(
                        rgba(color(base), .4),
                        rgba(color(base), .4)
                    ) center right #{$space*10.5} / #{$space*2} #{$space*2} no-repeat                    
                ;
            }
        }
            
            // footer 'header' 
            .footer__top {
                @include rem(height, $space*5.5);
                @include rem(padding-top, $space*3.75);
            }

                .footer__top-nav {
                    display: inline-block;
                    vertical-align: top;
                }
        
                .footer__top-socmed {
                    text-align: right;
                }

            // link to root
            .footer__brand {
                float: left;
            }

                .footer__brand-link {
                    display: inline-block;
                    vertical-align: middle;
                    @include rem(margin-right, $space*2);
                }

            .footer__link,
            .footer__heading a {

                &:hover {
                    text-decoration: underline;
                }    
            }
    }