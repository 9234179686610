/*----------------------------------------------------------------------------*\
    ALERT
    extends nobearcss sign
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .alert {
        font-weight: 600;
        color: color(blank);
        @include rem(margin-bottom, $space);
        @include rem(padding, $space);
    }

        .alert--danger {
            background: color(notice-alert);
        }

        .alert--success {
            background: color(notice-notify);
        }