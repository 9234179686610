/*----------------------------------------------------------------------------*\
    HELPERS
    various small helper classes
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .pimcore_area_edit_button {
        z-index: 1;
    }