/*----------------------------------------------------------------------------*\
    HELPERS
    Default classes almost always 
    
\*----------------------------------------------------------------------------*/

    // SIGN
    .sign{
        @include rem(margin-bottom, $space);
    }

    .sign-s{
        @include rem(margin-bottom, $space/2);
    }

    .sign-l{
        @include rem(margin-bottom, $space*2);
    }

    .sign-inv{
        @include rem(margin-bottom, -$space);
    }

    // LAYER
    .layer,
    .layer-s,
    .layer-l {
        @extend %cf !optional;
        display: block;
    }

    .layer{
        @include rem(padding, $space*2 0);
    }

    .layer-s{
        @include rem(padding, $space 0);
    }

    .layer-l{
        @include rem(padding, $space*4 0);
    }

    // TEXT ALIGNMENT
    .text-left{
        text-align: left;
    }

    .text-center{
        text-align: center;
    }

    .text-right{
        text-align: right;
    }
    
    // NOWRAP

    .nowrap{
        white-space: nowrap;
    }

    // VISUALLYHIDDEN
    .visuallyhidden {
        @include visuallyhidden;
    }

    // HELPERS BASED ON BREAKPOINT
    @each $state in $breakpoint-has-helpers {
        @include media-query(#{$state}) {
           
            // sign
            .sign--#{$state}{@include rem(margin-bottom, $space);}
            .sign-s--#{$state}{@include rem(margin-bottom, $space/2);}
            .sign-l--#{$state}{@include rem(margin-bottom, $space*2);}
            .sign-inv--#{$state}{@include rem(margin-bottom, -$space);}

            // layer
            .layer--#{$state}{@include rem(padding, $space*2 0);}
            .layer-s--#{$state}{@include rem(padding, $space 0);}
            .layer-l--#{$state}{@include rem(padding, $space*4 0);}

            // Text alignment
            .text-left--#{$state}{text-align: left;}
            .text-center--#{$state}{text-align: center;}
            .text-right--#{$state}{text-align: right;}

            // visuallyhidden
            .visuallyhidden--#{$state} {@include visuallyhidden;}
        }
    }
