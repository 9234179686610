/*----------------------------------------------------------------------------*\
    Arrange
    This component lets you lay out a row of cells in various ways. You can
    specify whether a cell should be wide enough to fit its content, or take up
    the remaining space in the row. It's also possible to give all cells an
    equal width, and to control their vertical alignment.


\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $use-arrange: true !default;
    $breakpoint-has-collapsed-arrange: () !default;

/*  Mixins
\*----------------------------------------------------------------------------*/

    @mixin arrange-collapse () {
        display: block; /* 1 */

        > .arrange__size-fill,
        > .arrange__size-fit {
            display: block; /* 1 */
            width: 100%; /* 2 */
            padding-left: 0; /* 3 */

            img {
                max-width: 100%; /* 4 */
                height: auto; /* 4 */
            }
        }
    }

/*  Component
\*----------------------------------------------------------------------------*/

    @if $use-arrange == true{

        .arrange {
            display: table;
            margin: 0;
            padding: 0;
            table-layout: auto;
            width: 100%;
        }

        .arrange__size-fill,
        .arrange__size-fit {
            display: table-cell;
            padding: 0;
            vertical-align: top;
        }

        .arrange__size-fill {
            width: 100%;
        }

        .arrange__size-fit img {
            max-width: none;
            width: auto;
        }

        // VERTICAL ALIGNMENT MODIFIERS
        .arrange--top {

            > .arrange__size-fill,
            > .arrange__size-fit {
                vertical-align: top;
            }
        }

        .arrange--middle {

            > .arrange__size-fill,
            > .arrange__size-fit {
                vertical-align: middle;
            }
        }

        .arrange--bottom {

            > .arrange__size-fill,
            > .arrange__size-fit {
                vertical-align: bottom;
            }
        }

        // EQUAL-WIDTH MODIFIER
        .arrange--equalwidth {
            table-layout: fixed;

            > .arrange__size-fill,
            > .arrange__size-fit {
                width: auto;
            }
        }

        // GUTTER MODIFIER
        .arrange--gutter {

            > .arrange__size-fill,
            > .arrange__size-fit {
                @include rem(padding-left, $space);

                &:first-child {
                    padding-left: 0;
                }
            }
        }

        // RESPONSIVE
        .arrange--collapse {
            @include arrange-collapse;
        }

        @each $state in $breakpoint-has-collapsed-arrange {
            @include media-query(#{$state}) {
                .arrange--collapse--#{$state} {
                    @include arrange-collapse;
                }
            }
        }
    }