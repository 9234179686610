/*----------------------------------------------------------------------------*\
    NAVIGATION LANGUAGE
    Switcher
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .nav--lang {
        list-style: none;
        @include media-query(gt-palm) {
        display: inline-block;
        }
        margin: 0;
        text-align: right;
        @include rem(padding-bottom, $space);

        .nav__legend {
            display: none;
        }

        // list item
        .nav__item {
            overflow: hidden;
            position: relative;
            display: inline-block;
            text-align: center;

            &.active {
                top: 0;
                background-color: transparent;

                .nav__link {
                    color: color(base);
                }
            }
        }

        // anchor
        .nav__link {
            display: block;
            text-decoration: none;
            white-space: nowrap;
            color: rgba(color(base), .5);
            text-align: right;
            @include font-size($fontsize-m, 1.5);
            @include rem(padding-right, $space);
        }
    }



/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(desk){
         
        .nav--lang {
            transform: translateX($space-s);
            background-color: color(blank);
            transition: 
                background-color $dur-hide $transition-hide,
                box-shadow $dur-hide $transition-hide;
            padding: 0;
            // @include rem(padding, $space*1.5 0 0 0);

            &:hover {
                background-color: color(base-light);
                box-shadow: 2px 2px rgba(color(base), .5);
                transition: 
                    background-color $transition-show,
                    box-shadow $transition-show;

                .nav__item {
                    transition: height $transition-show;
                    opacity: 1;
                    pointer-events: all;
                    @include rem(height, $space*1.5);

                    .nav__link {
                        transition: opacity $dur-show $transition-show ;
                        opacity: 1;
                    }

                    &.active .nav__link {
                        color: color(brand-sec);
                    }
                }
            }

            .nav__legend {
                position: absolute;
                top: 0;
                right: 100%;
                display: block;
                @include font-size($fontsize-m, 1.5);

                &--zh{
                    width: $space*2;
                }
            }

            // list item
            .nav__item {
                display: block;
                width: 100%;
                height: 0;
                pointer-events: none;
                transition: height $dur-hide $transition-hide;

                &.active {
                    // position: absolute; // always on top
                    height: auto;
                    opacity: 1;
                    // top: 0;
                    // text-align: right;

                    .nav__link {
                        opacity: 1;
                    }
                }
            }

            // anchor
            .nav__link {
                padding: 0;
                opacity: 0;
                width: 100%;
                color: color(base);
                transition: opacity $transition-hide;
                @include rem(padding, 0 $space-s);

                &:hover {
                    color: color(brand);
                }
            }
        }
    }