/*----------------------------------------------------------------------------*\
    Toggle
    Based on toggle.js
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    *[data-role=togglee][data-state=passive]{
        display: none;
    }