/*----------------------------------------------------------------------------*\
    Navigation
    .nav

\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $use-navigation: true !default;

/*  Component
\*----------------------------------------------------------------------------*/

    @if $use-navigation == true {

        .nav {
            @extend .cf;
            padding: 0;
            margin: 0;
            list-style: none;

            > li {

                &,
                > a {
                    display: inline-block;
                }
            }
        }

        // MODIFIER: VERTICAL
        .nav--stacked {
            > li {
                display: list-item;

                > a {
                    display: block;
                }
            }
        }

        // MODIFIER: CENTER
        .nav--center {
            text-align: center;
        }


        // MODIFIER: BREADCRUMBS
        .nav--breadcrumb > li + li:before {
            content: "» ";
        }
        .nav--breadcrumb-path > li + li:before {
            content: "/ ";
        }
        .nav--breadcrumb > li + li[data-breadcrumb]:before {
            content: attr(data-breadcrumb)" ";
        }


        // MODIFIER: PAGINATION
        .nav--pagination {
            text-align: center;

            > li {
                @include rem(padding, $space/2);

                > a {
                    @include rem(padding, $space/2);
                    @include rem(margin, -$space/2);
                }
            }
        }

        .nav--pagination__first a:before {
            content: "« ";
        }
        .nav--pagination__last a:after {
            content: " »";
        }
    }