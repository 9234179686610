/*----------------------------------------------------------------------------*\
    $VARS
    Any variables you find set in nobearcss’ `_defaults.scss` that you do not wish
    to keep, simply redefine here. This means that if nobearcss, for example,
    sets your `$base-font-size` at 16px and you wish it to be 14px, simply
    redeclare that variable in this file. nobearcss ignores its own variables
    in favour of using your own, so you can completely modify how nobearcss
    works without ever having to alter the framework itself.
\*----------------------------------------------------------------------------*/

/*----------------------------------------------------------------------------*\
    $CONTENTS
\*----------------------------------------------------------------------------*/

/*
CONTENTS................You're reading it, LOL
MODULES.................Enable/disable modules
OVERRIDES...............Variables that should override defaults
CUSTOM..................Your own variables
*/





/*----------------------------------------------------------------------------*\
    $MODULES
    All of nobearcss’ objects and abstractions are initially turned off by
    default. This means that you start any project with as little as possible,
    and introducing objects and abstractions is as simple as switching the
    following variables to `false`.
\*----------------------------------------------------------------------------*/


$site-width:                       1024px;
$site-width-m:                     1144px;
$site-width-l:                     1440px;

$base-font-size:                   16px;
$base-line-height:                 24px;
$line-height-ratio:                $base-line-height/$base-font-size;

$space:                            $base-line-height;
$space-xs:                         $space/4;
$space-s:                          $space/2;
$space-l:                          $space*2;
$space-xl:                         $space*4;

/* Modules */
$use-buttons:                      true;
$use-island:                       true;
$use-navigation:                   true;

/* layout */
$use-layout:                       false;

/* arrange */
$use-arrange:                      true;
$arrange-gutter:                   $space;
$breakpoint-has-collapsed-arrange: ();

/* grid */
$use-grid:                         true;
$enable-grid-rev:                  true;
$enable-grid-right:                false;
$enable-grid-center:               true;
$enable-grid-vertical:             true;
$enable-grid-narrow:               false;
$enable-grid-wide:                 false;
$enable-grid-flush:                true;

/* units */
$dimensions-use-units:             true;

/* units / divisions */
$dimensions-divisions:             5 12;

/* units / breakpoints */
$breakpoints: (
        'palm'      '(max-width: 480px)',
        'gt-palm'   '(min-width: 481px)',
        'lap'       '(min-width: 481px) and (max-width: 1024px)',
        'lt-desk'   '(max-width: 1024px)',
        'desk'      '(min-width: 1025px)'
);
$dimensions-breakpoint-has-units:  (
        'palm',
        'gt-palm',
        'lap',
        'desk'
);

/* push */
$dimensions-use-push:              true;
$dimensions-breakpoint-has-push:   (
        'gt-palm'
);

/* pull*/
$dimensions-use-pull:              false;
$dimensions-breakpoint-has-pull:   ();

/* helpers */
$breakpoint-has-helpers: (
        'palm',
        'gt-palm'
);

/* helpers */
$breakpoint-has-island: ();

/* arrange and layout */
$dimensions-breakpoint-has-fit:    ();
$dimensions-breakpoint-has-fill:   ();

/* fixed ratio */
$use-fixedratio:                   true;

/* icons */
$use-icons:                        false;

/* images */
$use-image:                        false;







/*----------------------------------------------------------------------------*\
    $TYPOGRAPHY
\*----------------------------------------------------------------------------*/

// font sizes, see wiki
$fontsize-xl:       64px;
$fontsize-l:        30px;
$fontsize-m:        $base-font-size;
$fontsize-s:        10px;

$font-base:        'Barlow', sans-serif;
$font-brand:       $font-base;




/*----------------------------------------------------------------------------*\
    $COLORS
\*----------------------------------------------------------------------------*/

// extend when needed, see wiki

$colors: (
    base:                           #34495E,
    base-dark:                      saturate(darken(#34495E, 20%), 10%),
    base-med:                       #E1E4E7,
    base-light:                     #F5F6F7,

    blank:                          #FDFDFD,

    brand:                          #D91935,
    brand-dark:                     saturate(darken(#D91935, 10%), 5%),

    brand-sec:                      #005EB8,
    brand-sec-light:                desaturate(lighten(#005EB8, 50%), 25%),
    brand-sec-bright:               #4A90E2,

    notice-alert:                   #F77F00,
    notice-notify:                  #008148,

    percentage-25:                  #F77F00,
    percentage-50:                  #ffd84b,
    percentage-75:                  #008148,
    percentage-100:                 #4A90E2,


);

//TODO: color tint mixin? https://medium.com/@erikdkennedy/color-in-ui-design-a-practical-framework-e18cacd97f9e



/*----------------------------------------------------------------------------*\
    $ANIMATIONS
\*----------------------------------------------------------------------------*/

$ani:                               cubic-bezier(0.4, 0.0, 0.2, 1.0);
$ani-show:                          cubic-bezier(0.0, 0.0, 0.2, 1.0);
$ani-hide:                          cubic-bezier(0.4, 0.0, 1.0, 1.0);

//$dur:                               .300s;
//$dur-show:                          .225s;
//$dur-hide:                          .195s;
//$dur-large:                         .375s;

$dur:                               .0s;
$dur-show:                          .0s;
$dur-hide:                          .0s;
$dur-large:                         .0s;

$transition:                        $dur $ani;
$transition-show:                   $dur-show $ani-show;
$transition-hide:                   $dur-hide $ani-hide;
