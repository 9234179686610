/*----------------------------------------------------------------------------*\
    TABS
    switchable displayed content
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $tab-variants: ('brand', 'brand-sec', 'brand-sec-bright');

/*  Component
\*----------------------------------------------------------------------------*/

    .tabs{
        font-size: 0;
        @include rem(margin, 0 0 $space);
    }

        .tabs__tab{
            position: relative;
            display: inline-block;
            width: percentage(1/3);
            height: 0;
            padding-bottom: percentage(1/3);
        }

        .tabs__button {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
            color: color(blank);
            text-align: left;
            padding: 0;
            transition: 
                transform $transition,
                box-shadow $transition;
            outline-offset: -1px;

            &:active {
                transform: translateY(1px);
                
                &,
                & ~ .tabs__arrow {
                    transition: none;
                }

                & ~ .tabs__arrow {
                    transform: translateY(1px) rotate(90deg);
                }
            }

            &[data-state=active] {

                &:after,
                & ~ .tabs__arrow {
                    opacity: 1;
                }
            }
        }

        .tabs__label {
            position: absolute;
            font-weight: 700;
            text-transform: uppercase;
            hyphens: auto;
            @include font-size($fontsize-m, .5);
            @include rem(left, $space-s);
            @include rem(right, $space-s);
            @include rem(bottom, $space);
        }

        .tabs__icon{
            position: absolute;
            z-index: 2;
            display: block;
            fill: color(blank);
            transition: 
                transform $transition-hide;
            @include rem(width, $space);
            @include rem(height, $space);
            @include rem(left, $space-s);
            @include rem(top, $space-s);
        }

        .tabs__arrow {
            background-color: color(blank);
            z-index: 3;
            transform: rotate(90deg);
            border-radius: 50%;
            opacity: 0;
            position: absolute;
            margin-left: 50%;
            transition: transform $transition;
            @include rem(height, $space);
            @include rem(bottom, -$space-s);
            @include rem(width, $space);
            @include rem(left, -$space-s);
        }

    /*  Tabs on light grey bg
    \*----------------------------------------------------------------------------*/

        .tabs--bg-base-light {

            .tabs__arrow {
                background-color: color(base-light);
            }
        }

    /*  Tab colors
    \*----------------------------------------------------------------------------*/

        @each $tab-variant in $tab-variants{

            .tabs__tab--#{$tab-variant} {

                .tabs__button {
                    background-color: color(#{$tab-variant}) !important;
                    outline: 1px solid color(#{$tab-variant});
                }

                .tabs__arrow {
                    fill: color(#{$tab-variant});
                }
            }
        }

    /*  Tab content
    \*----------------------------------------------------------------------------*/

        .tabs-content {

        }

            .tabs__content {
                display: none;
                text-align: center;
                @include font-size($fontsize-m);

                &[data-state=active] {
                    display: block;
                }
            }

/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(gt-palm){

        .tabs {}

            .tabs__icon {
                @include rem(width, $space*2);
                @include rem(height, $space*2);
                @include rem(left, $space*1.5);
                @include rem(top, $space*1.5);
            }

            .tabs__label {
                transition: color $transition;
                @include font-size($fontsize-l, .5);
                @include rem(bottom, $space*1.5);
            }
    }

    @include media-query(desk){
            
        .tabs {}

            .tabs__tab{}

            .tabs__button {
                box-shadow: 2px 2px transparent;

                &:hover:not(:active) {
                    transform: translateY(-2px);
                    z-index: 2;
                    box-shadow: 2px 2px rgba(color(base), .5);

                    ~ .tabs__arrow {
                            transform: translateY(-2px) rotate(90deg);                        
                    }

                    ~ .tabs__icon {
                            transform: translateY(-2px);                        
                    }
                }
            }
    }