.product-grid {
  display: grid;
  grid-template-rows: min-content;
  gap: 16px;
  grid-template-columns: 1fr;

  @include media-query(gt-palm) {
    grid-template-columns: repeat(3, 1fr);
  }

  .product-card {
    position: relative;
    background-color: #fff;
    outline: 1px solid rgba(52,73,94,.1);
    text-decoration: unset;
    color: #34495e;

    .product-card__visual {
      position: relative;
      width: 100%;
      padding-bottom: 50%; /** 1/2 aspect-ratio */

      .product-card__image {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }

    .product-card__inner {
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 1.5rem;

      .product-card__inner__row {
        display: flex;
        flex-direction: column;

        &:last-child {
          margin-bottom: 32px;
        }
      }
    }

    .product-card__arrow {
      fill: #d91935;
      bottom: 1.5rem;
      height: 1.5rem;
      position: absolute;
      right: 1.5rem;
      width: 1.5rem;
    }
  }
}