/*----------------------------------------------------------------------------*\
    CAROUSEL
    slideable items
\*----------------------------------------------------------------------------*/

/*  Variables & imports
\*----------------------------------------------------------------------------*/

@import url('//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick.css');

/*  Component
\*----------------------------------------------------------------------------*/

    .carousel {
        position: relative;
        list-style: none;
        margin: 0;
        font-size: 0;
        white-space: nowrap;
        overflow-x: scroll;
        @include rem(margin, 0 $space*2 $space*-1);
        @include rem(padding, $space-s 0);

        &.slick-slider {
            overflow-x: visible;
        }

        &:before {
            content: '';
            position: absolute;
            pointer-events: none;
            z-index: 1;;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(
                90deg,
                color(blank) 0,
                rgba(color(blank), 0) #{valueToRem($space-s)},
                rgba(color(blank), 0) calc(100% - #{valueToRem($space-s)}),
                color(blank) 100%
            );
        }

        .bg--base-light & {

            &:before {
                background: linear-gradient(
                    90deg,
                    color(base-light) 0,
                    rgba(color(base-light), 0) #{valueToRem($space-s)},
                    rgba(color(base-light), 0) calc(100% - #{valueToRem($space-s)}),
                    color(base-light) 100%
                );
            }
        }
    }

        .carousel__item {
            display: inline-block;
            width: percentage(1/3);
            white-space: normal;
            @include rem(padding, $space-s);
        }

        .carousel__arrow {
            position: absolute;
            top: calc(50% - #{valueToRem($space)});
            border-radius: 50%;
            border: 0;
            padding: 0;
            background: transparent;
            box-shadow: 0 0 0 $space/4 color(base) inset;
            outline: 0;
            transition: 
                opacity    $transition,
                box-shadow $transition,
                transform  $transition;
            opacity: .5;

            &:active {
                transform: translateY(2px);
                transition: none;
                opacity: 1;

                .carousel__arrow-icon {
                    fill: color(base);
                }
            }

            &[aria-disabled=true] {
                opacity: .25;
                pointer-events: none;
            }
        }

            .carousel__arrow-icon {
                fill: color(base);
                transition: fill $transition;
                @include rem(width, $space-l);
                @include rem(height, $space-l);    
            }

        .carousel__arrow--left {
            @include rem(left, -$space*2);

            .carousel__arrow-icon {
                transform: scaleX(-1);
            }
        }

        .carousel__arrow--right {
            @include rem(right, -$space*2);
        }



/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(gt-palm){

        .carousel {
            // @include rem(margin, 0 -$space-s);
        }
    }

    @include media-query(desk){
         
        .carousel {}

            .carousel__item {}

            .carousel__arrow {

                &:hover:not(:active) {
                    box-shadow: 
                        0 0 0 $space/4 color(base) inset,
                        2px 2px rgba(color(base), 0.5)
                    ;
                    transform: translateY(-2px);
                    opacity: .75;
                }
            }
    }