/*----------------------------------------------------------------------------*\
    TOP LEVEL
    HTML, body, wrapper, site-width overrides
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    // the page
    body {
        font-family: $font-base;
        color: color(base);
        background-color: color(base-med);
    }

    // outer layout container
    .wrapper {
        background: color(blank);
        margin: 0 auto;
        @include rem(max-width, $site-width-l);
    }

    // content column
    .site-width {
        @include rem(padding, 0 $space-s);
    }

    .site-width--m {
        @include rem(max-width, $site-width-m);
    }

    .site-width-half {
        margin-left: auto;
        @include rem(max-width, $site-width/2);
        @include rem(padding, 0 $space-s);
    }

    .site-width-half--sec {
        margin-left: 0;
        margin-right: auto;
    }

    .st-custom-button {
        &:hover {
            cursor: pointer;
        }
    }


/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(gt-palm){
            
        .site-width-half--padded {
            @include rem(padding-right, $space*2.5);

            &.site-width-half--sec {
                @include rem(padding-right, $space-s);
                @include rem(padding-left, $space*2.5);
            }
        }
    }