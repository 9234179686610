/*----------------------------------------------------------------------------*\
    Form
    Form elements
    
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $use-forms: true !default;

/*  Component
\*----------------------------------------------------------------------------*/

    @if $use-forms == true{

        input[type=text],
        input[type=email],
        input[type=tel],
        input[type=number],
        input[type=password],
        input[type=search],
        textarea,
        select,
        .input{
            appearance: none;
            border: 0;
            border-radius: 0;
            border: 1px solid color(base);
            background-color: color(blank);
            color: color(base);
            @include rem(padding, $space-s);
            @include rem(max-width, $space*20);
            @include font-size($base-font-size, 1);

            &:focus{
                outline: 1px solid color(brand);
            }
        }

        textarea{
            resize: vertical;
        }

        select{
            background-image: url(../gfx/nobearcss/icon-arrow.svg);
            background-repeat: no-repeat;
            background-size: 12px 7px;
            background-position: right $space*0.75 center;
            @include rem(padding, $space-s $space*1.5 $space-s $space-s);
        }
    }