/*----------------------------------------------------------------------------*\
    SQUEEZE
    Padding at the sides
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .squeeze {
        @include rem(padding, 0 $space);
    }

    .squeeze-s {
        @include rem(padding, 0 $space-s);
    }



/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(desk){
         
        .squeeze--desk {
            @include rem(padding, 0 $space);
        }
    }
