/*----------------------------------------------------------------------------*\
    Island
    .island

\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $use-island: true !default;


/*  Component
\*----------------------------------------------------------------------------*/

    @if $use-island == true {
        .island,
        .island-s,
        .island-l {
            @extend %module;
            @extend %cf;
            display: block;
        }

        .island {
            @include rem(padding, $space);
        }

        .island-s {
            @include rem(padding, $space-s);
        }

        .island-l {
            @include rem(padding, $space-l);
        }
    }

/*  Media queries
\*----------------------------------------------------------------------------*/

    // ISLAND BASED ON BREAKPOINT
    @each $state in $breakpoint-has-island {
        @include media-query(#{$state}) {
           
            // island
            .island--#{$state} {
                @include rem(padding, $space);
            }

            .island-s--#{$state} {
                @include rem(padding, $space-s);
            }

            .island-l--#{$state} {
                @include rem(padding, $space-l);
            }
        }
    }
