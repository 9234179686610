/*----------------------------------------------------------------------------*\
    Shared
    html, body, .wrapper, .site-width
\*----------------------------------------------------------------------------*/

/*  Component
\*----------------------------------------------------------------------------*/

    html {
        font: #{($base-font-size/16px)*1em}/#{$line-height-ratio} sans-serif; /* 1 */
        overflow-y: scroll; /* 2 */
        min-height: 100%;
        -moz-osx-font-smoothing: grayscale; /* 3 */
        -webkit-font-smoothing: antialiased; /* 3 */
    }

    html, 
    body { 
        height: 100%; 
    }

    body {
        position: relative;
        min-height: 100%;
        -webkit-font-smoothing: antialiased;
        background-color: color(blank);
    }

    .wrapper {
        position: relative;
        min-height: 100%;
        height: auto;
        width: 100%;
        margin: 0 auto;
    }

    .site-width {
        display: block;
        margin: 0 auto;
        @include rem(max-width, $site-width);
        @include rem(padding, 0 $space);
    }


    /**
    * Applies vertical rhythm to designated elements
    * As per: http://csswizardry.com/2012/06/single-direction-margin-declarations
    */
    hgroup,
    .hgroup,
    ul,
    ol,
    dl,
    blockquote,
    p,
    address,
    table,
    fieldset,
    pre,
    hr {
        @include rem(margin-bottom, $space);
    }


    /**
    * Default indentation value for lists
    */
    ul,
    ol,
    dd {
        @include rem(margin-left, $base-line-height);
    }