/*----------------------------------------------------------------------------*\
    NAVIGATION FOOTER
    various menus in page bottom
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .nav--foot {
        list-style: none;
        margin: 0;

        // list item
        .nav__item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        // anchor
        .nav__link {
            text-decoration: none;
            color: rgba(color(blank), .5);

            &:active {
                color: rgba(color(blank), .75);
            }
        }

        .nav__link--inline {
            display: inline-block !important; 
        }
    }

    .nav--foot-sec {

        // list item
        .nav__item {}

        // anchor
        .nav__link {
            color: color(blank);
        }
    }



/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(desk){

        .nav--foot {

            // list item
            .nav__item {}

            // anchor
            .nav__link {

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }