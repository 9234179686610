/*----------------------------------------------------------------------------*\
    CARD
    Block with content, optional image
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

$card-colors: (
        'base',
        'base-med',
        'blank',
        'brand',
        'brand-sec-bright'
);

/*  Component
\*----------------------------------------------------------------------------*/

.card {
  -webkit-tap-highlight-color: transparent;
  background-color: color(blank);
  color: color(base);
  box-shadow: 0 2px 4px rgba(color(base), .5);
  @include rem(padding, $space);
}

// card with height equal to width
.card--square {
  position: relative;
  display: block;
  height: 0;
  padding: 0 0 100% 0;
  outline: 1px solid rgba(color(base), .1);
  box-shadow: none;

  .card__inner {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    @include rem(padding, $space);

    .image--container {
      overflow: hidden;
    }

    &.card__inner--bg-image {
      .card__date,
      .card__title {
        position: relative;
        z-index: 1;
      }

      background-image: url(/_default_upload_bucket/m3.jpg);
      background-position: center;
      background-size: cover;
      mix-blend-mode: luminosity;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
        z-index: 0;
      }
    }
  }

  .card__arrow {
    position: absolute;
    fill: color(brand);
    @include rem(width, $space);
    @include rem(height, $space);
    @include rem(right, $space);
    @include rem(bottom, $space);
  }

  &:active {
    background-color: color(brand);
    color: color(blank);
    transition: none;
    transform: translateY(1px);
    outline: 1px solid color(base-med);
    outline-offset: 0;

    .card__arrow {
      fill: color(blank);
      transition: none;
    }
  }

  &:focus {
    z-index: 1;
    outline: 1px solid color(base-med);
    outline-offset: 0;
  }
}

.card__date {
  font-weight: 700;
  display: block;
  @include rem(margin-bottom, $space-s);
}

.card__line {
  height: 1px;
}

.card__date,
.card__title {
  text-transform: initial;
}

  /*  Card with height twice the width, divided 50/50 into image/text
  \*----------------------------------------------------------------------------*/
.card--square-image {
  box-shadow: none;
  padding: 0 0 200% 0;

  .card__image {
    position: relative;
    height: 0;
    width: 100%;
    padding-bottom: 100%;
    background-size: cover;
    background-position: center;
  }

  .card__inner {
    top: 50%;
    height: 50%;
  }
}

/*  Colored cards
\*----------------------------------------------------------------------------*/

.card--colored {
  box-shadow: none;
  // padding: 0;
  color: color(blank) !important;

  .card__line {
    background-color: color(blank);
  }

  .card__image {
    opacity: .15;
    mix-blend-mode: luminosity;
  }

  &:active {
    transform: translateY(1px);
  }
}

/*  Media queries
\*----------------------------------------------------------------------------*/

@include media-query(desk) {

  .card {
  }

  // card with height equal to width
  .card--square {
    transition: background-color $transition,
    color $transition;

    .card__arrow {
      transition: fill $transition;
    }

    &:hover:not(:active) {
      background-color: color(brand);
      color: color(blank);
      outline: 1px solid rgba(color(base), .1);

      .card__arrow {
        fill: color(blank);
      }
    }
  }

  /*  Colored cards
  \*----------------------------------------------------------------------------*/

  .card--colored {
    transition: transform $transition,
    box-shadow $transition;
    box-shadow: 2px 2px transparent;

    &:hover:not(:active) {
      transform: translateY(-2px);
      box-shadow: 2px 2px rgba(color(base), .5);
    }
  }
}

.data-card-grid:nth-child(5n+1) {
  .card--colored-auto {
    background-color: #4a90e2;
  }
}

.data-card-grid:nth-child(5n+2) {
  .card--colored-auto {
    background-color: #e1e4e7;

    .card__inner_no-image {
      color: #34495e !important;

      .card__line {
        background-color: #34495e;
      }
    }
  }
}


.data-card-grid:nth-child(5n+3) {
  .card--colored-auto {
    background-color: #d91935;
  }
}

.data-card-grid:nth-child(5n+4) {
  .card--colored-auto {
    background-color: #34495e;
  }
}

.data-card-grid:nth-child(5n+5) {
  .card--colored-auto {
    background-color: #a2a2a2;
  }
}

.data-card-new-grid {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(1, 1fr);

  @include media-query(gt-palm) {
    grid-template-columns: repeat(4, 1fr);
  }
}
