.nav__legend {
  display: flex !important;
  gap: 16px;
}

.header-top {
  @include media-query(palm) {
    display: inline-block !important;
    position: relative !important;
    padding-bottom: 1.5rem;
    right: unset !important;

    > a {
      padding-right: 1.5rem;
    }
  }


  @include media-query(gt-palm){
    display: flex;
  }

  display: none;
  z-index: 2;
  position: absolute;
  top: 0;
  right: 220px;
  flex-direction: row;
  gap: 16px;

  a {
    font-size: 1rem;
    line-height: 2.25;
    text-decoration: none !important;
    color: rgb(52, 73, 94) !important;
    white-space: nowrap;

    &:hover {
      color: #D91935 !important;
    }
  }
}
