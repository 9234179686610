/*----------------------------------------------------------------------------*\
    LIST
    ul / ol / / li + dl / dt / dd
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    /* Unordered list
    \*----------------------------------------------------------------------------*/
        ul{

            li{}
        }

        ul.list--filters {
            list-style: none;
            @include rem(margin, 0 0 $space 0);

            .list__item{
                display: block;
            }

                .list__input{
                    margin: 0;
                    display: inline-block;
                    vertical-align: top;
                    float: none;
                }

                .list__label {
                    display: inline-block;
                    vertical-align: top;
                    @include rem(margin-bottom, $space-s);
                }

            .list__subitem {
                @include rem(padding-left, $space);
            }
        }

    /* Ordered list
    \*----------------------------------------------------------------------------*/

        ol{

            li{}
        }

    /* (un)ordered list items
    \*----------------------------------------------------------------------------*/

        li{}

    /* Definition list
    \*----------------------------------------------------------------------------*/

        dl{}

            dt{}

            dd{}


    
/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(desk){
         
        ul.list--filters {

            .list__label {
                margin-bottom: 0;
            }
        }
    }