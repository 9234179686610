/*----------------------------------------------------------------------------*\
    jQuery slider
    .ui-slider
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/
    .slider__header{
        margin-bottom: 0;
        .slider__label, .slider__input{
            display: inline-block;
            vertical-align: center;
        }

        .slider__input{
            border: 0;
            color: color(brand);
            font-weight: bold;
            outline: 0;
            box-shadow: none;
            padding: 0;
            height: auto;
            width: auto;
            background: transparent;
            color: color(blank);
            margin-bottom: $space/2;
            font-weight: 400;

            &:focus{
                outline: 0;
            }
        }
    }

    .ui-widget-content{
        background-color: rgba(color(blank), 0.25);
        // border: 1px solid color(base-med);
    }

    .ui-widget-header{
        background-color: rgba(color(blank), 0.5);
    }

    .ui-slider-handle{
        background-color: color(blank);
        outline: 0;
    }

/*  jQuery ui style
\*----------------------------------------------------------------------------*/
    .ui-slider {
    	position: relative;
    	text-align: left;
    }
    .ui-slider .ui-slider-handle {
    	position: absolute;
    	z-index: 2;
    	width: 1.2em;
    	height: 1.2em;
    	cursor: default;
    	-ms-touch-action: none;
    	touch-action: none;
    }
    .ui-slider .ui-slider-range {
    	position: absolute;
    	z-index: 1;
    	font-size: .7em;
    	display: block;
    	border: 0;
    	background-position: 0 0;
    }

    /* support: IE8 - See #6727 */
    .ui-slider.ui-state-disabled .ui-slider-handle,
    .ui-slider.ui-state-disabled .ui-slider-range {
    	filter: inherit;
    }

    .ui-slider-horizontal {
        height: .8em;
        width: 90%;
        display: block;
        margin: 0 auto;
    }
    .ui-slider-horizontal .ui-slider-handle {
    	top: -.25em;
    	margin-left: -.6em;
    }
    .ui-slider-horizontal .ui-slider-range {
    	top: 0;
    	height: 100%;
    }
    .ui-slider-horizontal .ui-slider-range-min {
    	left: 0;
    }
    .ui-slider-horizontal .ui-slider-range-max {
    	right: 0;
    }

    .ui-slider-vertical {
    	width: .8em;
    	height: 100px;
    }
    .ui-slider-vertical .ui-slider-handle {
    	left: -.3em;
    	margin-left: 0;
    	margin-bottom: -.6em;
    }
    .ui-slider-vertical .ui-slider-range {
    	left: 0;
    	width: 100%;
    }
    .ui-slider-vertical .ui-slider-range-min {
    	bottom: 0;
    }
    .ui-slider-vertical .ui-slider-range-max {
    	top: 0;
    }
