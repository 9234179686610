/*----------------------------------------------------------------------------*\
    $COLOR
\*----------------------------------------------------------------------------*/

    /* Color function
    Using colors in css example:
    background-color: color(brand);
    \*------------------------------------------------------------------------*/
    @function color($key) {
        @if map-has-key($colors, $key) {
            @return map-get($colors, $key);
        }

        @error "Unknown `#{$key}` in $colors.";
        @return null;
    }

    /**
    * Backgrounds & colors
    */
    @each $color, $value in $colors{
        
        .bg--#{$color} {
            background-color: $value !important;
        }
        .color--#{$color} {

            @if $color == 'blank' {
                &,
                a {
                    color: $value !important;
                }
            } @else {
                color: $value !important;
            }
        }
        .fill-#{$color} {
            fill: $value !important;
        }
    }



/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(gt-palm){

        .bg--sidebar-left{
            background: linear-gradient(
                color(brand-sec),
                color(brand-sec)
            ) no-repeat top left / 25% calc(100% - #{$space*4.25});
        }
    }

.bg-white {
    color: #34495E;
}