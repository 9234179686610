/*----------------------------------------------------------------------------*\
    GRID
    extends nobearcss grid
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $grid-broken-offset: 4;

/*  Component
\*----------------------------------------------------------------------------*/

    .grid--broken {
        
        .grid__cell {
            position: relative;
        }
    }

        .grid__cell--front {

            & > * {
                position: relative;
                z-index: 2; // above color overlay
            }

            &:after {
                content: '';
                position: absolute;
                z-index: 1; // above image
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: color(brand);
                mix-blend-mode: multiply;
            }
        }

        .grid__cell--back {
            // position: absolute;
            z-index: 0;
            width: calc(100% - #{$space*$grid-broken-offset/2 - $space-s});
            @include rem(left, $space*$grid-broken-offset/2);
            @include rem(margin-bottom, -$space*$grid-broken-offset/2);
        }



/*  Media queries
\*----------------------------------------------------------------------------*/

        @include media-query(gt-palm){
             
            .grid--broken {
        
                .grid__cell {
                    @include rem(margin-bottom, $space*$grid-broken-offset);
                }
            }
                
                .grid__cell--front {
                    @include rem(top, $space*$grid-broken-offset);
                    @include rem(left, $space*$grid-broken-offset/2);
                }

                .grid__cell--back {
                    position: relative;
                    @include rem(left, -$space*$grid-broken-offset/2);
                }
        }