/*----------------------------------------------------------------------------*\
    NAVIGATION ICONS
    small images in footer
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .nav--icons {

        .nav__item {

        }

        .nav__link {
            font-size: 0;
            @include rem(padding, $space-xs);
        }
    }