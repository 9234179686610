.pagination {
  display: flex;
  gap: 8px;
  justify-content: center;

  .page-item {
    color: #34495E;
    list-style: none;
    display: block;
    width: 25px;
    height: 25px;
    border: 1px solid #a2a2a2;
    text-align: center;
    line-height: 21px;
    text-decoration: unset;
    
    &:hover {
      border-color: #d91935;
      background-color: #e1e4e7;
    }

    &.active {
      cursor: pointer;
      color: #d91935;
      border-color: #d91935;
    }
  }

  .page-navigation {
    color: #34495E;
    text-decoration: unset;
  }
}