/*----------------------------------------------------------------------------*\
    Extends
    Clearfix, Module, Truncate

\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

    $use-clearfix: true !default;
    $use-module:   true !default;
    $use-truncate: true !default;

/*  Component
\*----------------------------------------------------------------------------*/

    // CLEARFIX
    @if $use-clearfix == true {
        .cf,
        %cf {
            &:after {
                content: "";
                display: table;
                clear: both;
            }
        }
    }

    // MODULE
    @if $use-module == true {
        .module,
        %module {
            > *:last-child,
            > *:last-child > *:last-child,
            > *:last-child > *:last-child > *:last-child,
            > *:last-child > *:last-child > *:last-child > *:last-child {
                margin-bottom: 0;
            }
        }
    }

    // TRUNCATE
    @if $use-truncate == true {
        .truncate,
        %truncate{
            @include truncate(); 
        }
    }