.leaflet-grid {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(2, 1fr);

  @include media-query(gt-palm) {
    grid-template-columns: repeat(3, 1fr);
  }

  .leaflet {
    display: grid;

    .leaflet-content {
      display: grid;
      border: 1px solid #34495E;

      b {
        padding: 8px;
      }
    }

    .button--leaflet {
      padding: 8px;
      background-color: #34495E;
      color: #fff;
      border: unset !important;
      box-shadow: unset !important;

      &:hover {
        background-color: #fff;
        color: #34495E;
      }

    }

    picture {
      display: block;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}