/*----------------------------------------------------------------------------*\
    BREADCRUMBS
    Description
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    .breadcrumbs{
        list-style: none;
        margin-left: 0;
        font-size: 0;
    }

        .breadcrumbs__item {
            display: inline-block;

            &:not(:last-child):after {
                content: '>';
                display: inline-block;
                @include font-size($base-font-size);
                @include rem(margin, 0 $space-xs);
            }
        }
        
            .breadcrumbs__link {
                @include font-size($base-font-size);
            }