/*----------------------------------------------------------------------------*\
    TYPOGRAPHY
    text etc
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    h1, h2, h3, h4, h5, h6, .heading {
        font-family: $font-brand;
        font-weight: 700;
        text-transform: uppercase;
    }

    h1,
    .heading--1 {
        @include font-size($fontsize-l, .75);
    }

    h2,
    .heading--2 {
        @include font-size($fontsize-l, .75);
    }

    h3, h4, h5, h6 {
        @include font-size($fontsize-m);
    }

    .weight-light {
        font-weight: 300;
    }
    
    .weight-semibold {
        font-weight: 600;
    }
    
    .heading-light {
        font-weight: 300;
        text-transform: initial;
    }

    p,
    .normalis {
        @include font-size($base-font-size);
    }

    .text-intro {
        @include font-size(18px);
    }

    .text-small {
        @include font-size($fontsize-s);
    }

    a {
        color: color(brand-sec);
        
        &:focus {
            outline-color: color(brand-sec);
            outline-width: 2px;
            outline-offset: -2px;
        }

        &:-moz-focusring {
            outline: 1px solid color(brand-sec-light);
            outline-offset: -1px;
        }
    }

    hr {
        background-color: color(brand);
        border: 0;
        margin: 0 auto 0 0;
        @include rem(height, $space-xs);
        @include rem(width, $space*2);
    }

    .hr-centered {
        display: block;
        @include rem(margin, 0 auto $space);
    }

    

/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(desk){
         
        h1,
        .heading--1 {
            @include font-size($fontsize-xl, 5/6);
        }

        h2,
        .heading--2 {
            @include font-size($fontsize-l);
        }

        hr {
            @include rem(height, $space-s);
            @include rem(width, $space*3.5);    
        }
    }