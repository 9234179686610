/*----------------------------------------------------------------------------*\
    Buttons
    .button
    
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/

$use-buttons: true !default;

/*  Component
\*----------------------------------------------------------------------------*/

@if $use-buttons == true{
    
    .button {
        position: relative;
        display: inline-block;
        margin: 0;
        border: 1px solid #000;
        color: inherit; /* 1 */
        cursor: pointer;
        font: inherit; /* 2 */
        line-height: 2; /* 3 */
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        white-space: nowrap; /* 4 */
        -webkit-appearance: none; /* 5 */
        @include rem(padding, $space-xs $space);

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }

        /**  Remove excess padding and border in Firefox 4+ */
        &::-moz-focus-inner {
            border: 0;
            padding: 0;
        }
    }


    // BUTTON SIZE MODIFIERS
    .button--s {
        @include rem(padding, 0 $space-s);
        @include font-size($fontsize-s);
    }
    .button--l {
        @include rem(padding, $space-s $space-l);
    }
    .button--xl {
        @include rem(padding, $space-s $space-l);
        @include font-size($fontsize-l);
    }

    // FULL WIDTH BUTTON MODIFIER
    .button--full {
        display: block;
        width: 100%;
    }
}