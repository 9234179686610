/*----------------------------------------------------------------------------*\
    FORM
    extends nobearcss framework form styling
\*----------------------------------------------------------------------------*/

/*  Variables
\*----------------------------------------------------------------------------*/



/*  Component
\*----------------------------------------------------------------------------*/

    label {
        display: block;

        &.required {

            &:not(:empty){

                &:after{
                    content: '*';
                }
            }
        }
    }

    // all fields
    input,
    textarea, 
    select, 
    .input {
        @include rem(margin-bottom, $space);
    }

    // text input fields
    input[type=text], 
    input[type=email], 
    input[type=tel], 
    input[type=number], 
    input[type=password], 
    input[type=date],
    textarea {
        outline: 1px solid rgba(color(base), .1);
        box-shadow: 2px 2px 2px rgba(color(base), .25) inset; 

        &:focus {
            outline: 1px solid color(base);
            outline-offset: 0;
        }

        &[readonly=readonly] {
            background-color: color(base-light);
            pointer-events: none;
        }

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: rgba(color(base), .5);
        }
        &::-moz-placeholder { /* Firefox 19+ */
            color: rgba(color(base), .5);
        }
        &:-ms-input-placeholder { /* IE 10+ */
            color: rgba(color(base), .5);
        }
        &:-moz-placeholder { /* Firefox 18- */
            color: rgba(color(base), .5);
        }
    }
    
    // single line fields
    input[type=text], 
    input[type=email], 
    input[type=tel], 
    input[type=number], 
    input[type=password], 
    input[type=date], 
    select {
        @include rem(height, $space*2);
    }

    // wide fields
    input[type=text], 
    input[type=email], 
    input[type=tel], 
    input[type=number], 
    input[type=password], 
    input[type=date], 
    textarea, 
    select, 
    .input {
        width: 100%;
        max-width: 100%;
        border: 0;
        border-radius: 0;
        // @include rem(border-radius, $space-xs !important);

        &:focus {
        }
    }

    //small fields
    input[type=checkbox],
    input[type=radio] {
        float: left;
        @include rem(width, $space * 0.75);
        @include rem(height, $space * 0.75);
        margin: 4px 8px 0 0;

        + label {
            overflow: hidden;
            @include rem(margin-bottom, $space);
        }
    }

    // date field{
    input[type=date] {
        vertical-align: top;

        &::-webkit-clear-button,
        &::-webkit-inner-spin-button{
            position: relative;
            display: block;
            @include rem(width, $space);
            @include rem(height, $space);
        }
    }

    textarea {
        @include rem(height, $space*4);
    }

    // TODO: explain in wiki why this needs an image
    select{ // TODO: browsertesting
        outline: 1px solid color(base);
        background: 
            color(blank);
        background-image:
            url(../gfx/theme/icon-circle-arrow-down.svg),
            url(../gfx/theme/icon-circle-arrow-down-blank.svg);
        background-position:
            top #{valueToRem($space-s)} right #{valueToRem($space-s)}, 
            top #{valueToRem(-$space)} right #{valueToRem($space-s)};
        background-repeat: no-repeat;
        -webkit-appearance: none;
        transition: 
            background $transition,
            color      $transition;
        @include rem(padding-right, $space*2);

        &:focus {
            outline-offset: 0;
            outline: 1px solid color(base);
            color: color(blank);
            background-color: color(base);
            background-position:
                top #{valueToRem($space-l)} right #{valueToRem($space-s)}, 
                top #{valueToRem($space-s)} right #{valueToRem($space-s)};
        }
    }
        


/*  Media queries
\*----------------------------------------------------------------------------*/

    @include media-query(desk){

        form {}

            select{

                &:hover {
                    cursor: pointer;
                    color: color(blank);
                    background-color: color(base);
                    background-position:
                        top #{valueToRem($space-l)} right #{valueToRem($space-s)}, 
                        top #{valueToRem($space-s)} right #{valueToRem($space-s)};
                }
            }
    }