/*----------------------------------------------------------------------------*\
    Reset
\*----------------------------------------------------------------------------*/

/*  Component
\*----------------------------------------------------------------------------*/

    html {
        box-sizing: border-box;
    }

    * {
        &,
        &:before,
        &:after {
            box-sizing: inherit;
        }
    }

    /**
    * The usual resetting of margins, paddings, borders etc.
    */
    blockquote,
    dl,
    dd,
    ol,
    ul,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    pre,
    fieldset,
    hr {
        margin: 0;
    }

    fieldset,
    ol,
    ul {
        padding: 0;
    }

    iframe,
    fieldset {
        border: 0;
    }

    /**
    * Remove extra vertical spacing when nesting lists.
    */
    li {
        > ul,
        > ol {
            margin-bottom: 0;
        }
    }

    /**
    * 1. Fix an odd quirk whereby, without this, code blocks are rendered at a
    *    font-size smaller than 1em.
    */
    code,
    kbd,
    pre,
    samp {
        font-family: monospace, monospace; /* 1 */
    }

    /**
    * Suppress the focus outline on links that cannot be accessed via keyboard.
    * This prevents an unwanted focus outline from appearing around elements that
    * might still respond to pointer events.
    */
    [tabindex="-1"]:focus {
    outline: none !important;
    }

    /**
    * Image
    *
    * 1. By default all images are fluid
    * 2. Render `alt` visually offset when images don't load
    */
    img {
        max-width: 100%; /* 1 */
        font-style: italic; /* 2 */
    }